import { DispatchPreview } from "@api/graphql/generated/generated-types";
import { ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import DisplayServiceType from "@src/servicetypes/components/DisplayServiceType";
import { StopTimeStatusBadge } from "@src/shipments/components/StopTimeStatusBadge";
import { makeSubtext } from "@src/shipments/utils/makeDispatchCandidateViews";
import { makeShipmentRowFromDispatchPreview } from "@src/shipments/utils/shipmentRowFormatter";
import { Tooltip } from "@src/common/components/Tooltip";
import { getShipmentName } from "@src/schedules/utils/schedulesNameGenerators";

interface DispatchPreviewItemProps {
  preview: Omit<DispatchPreview, "driver"> & {
    shipment: {
      driver?: {
        firstName: string;
        lastName: string;
        photoUrl?: string | null;
      } | null;
    };
  };
  className?: string;
}

function DispatchPreviewItem({ preview, className }: DispatchPreviewItemProps) {
  const subText = makeSubtext(preview as DispatchPreview, false, true);
  const shipmentRow = makeShipmentRowFromDispatchPreview(
    preview as DispatchPreview
  );
  const orderName = getShipmentName(
    shipmentRow?.shipment || null
  )

  return (
    <div
      className={`flex flex-col text-xs gap-2 p-1 hover:bg-gray-50 hover:cursor-pointer hover:shadow-md rounded-md m-1.5 ${className}`}
    >
      <div className="flex items-center gap-3 font-bold justify-between">
        <p className="truncate">
          {preview.shipment.order.displayId}.{" "}
          {preview.shipment.order.endCustomer.companyName}
        </p>
        <p className="truncate">{orderName}</p>
      </div>
      <div className="flex justify-between items-center truncate">
        {subText}
      </div>
      <div className="flex items-center gap-1 justify-between">
        <DisplayServiceType
          serviceType={preview.shipment.order.serviceType?.name || ""}
          color={preview.shipment.order.serviceType?.color || ""}
        />
        <div className="flex items-center">
          <MapPinIcon className="w-4 h-4" />
          <div>{preview.shipment.stops.length}</div>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-xs truncate font-bold mb-0.5">
          <Tooltip content={"Ready At"}>
            {shipmentRow?.pickup?.timeWindowText}
          </Tooltip>
          {shipmentRow?.pickup && (
            <StopTimeStatusBadge
              className="w-fit"
              color={shipmentRow?.pickup.badgeMeta.backgroundColor}
              size={"xs"}
              icon={ClockIcon as React.FC<React.SVGProps<SVGSVGElement>>}
              showBadge={shipmentRow?.pickup.badgeMeta.shouldShowBadge}
              tooltipContent={shipmentRow?.pickup.timeWindowText || ""}
            >
              <div className="font-normal truncate text-xs">
                {shipmentRow?.pickup.badgeMeta.badgeText}
              </div>
            </StopTimeStatusBadge>
          )}
        </p>
        <p className="text-xs truncate font-bold mb-0.5 flex flex-col items-end">
          {shipmentRow?.dropoff?.timeWindowText !== "None" && (
            <Tooltip content={"Due At"}>
              {shipmentRow?.dropoff?.timeWindowText}
            </Tooltip>
          )}
          {shipmentRow?.dropoff && (
            <StopTimeStatusBadge
              className="w-fit"
              color={shipmentRow?.dropoff.badgeMeta.backgroundColor}
              size={"xs"}
              icon={ClockIcon as React.FC<React.SVGProps<SVGSVGElement>>}
              showBadge={shipmentRow?.dropoff.badgeMeta.shouldShowBadge}
              tooltipContent={shipmentRow?.dropoff.timeWindowText || ""}
            >
              <div className="font-normal text-xs truncate">
                {shipmentRow?.dropoff.badgeMeta.badgeText}
              </div>
            </StopTimeStatusBadge>
          )}
        </p>
      </div>
    </div>
  );
}

export default DispatchPreviewItem;
