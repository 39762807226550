import React from "react";
import { Driver, TaskStatus } from "@api/graphql/generated/generated-types";
import { DriverAvatar } from "../Avatar";
import StatusDisplay from "@src/common/components/StatusDisplay";
import classNames from "classnames";

interface DriverNameCellProps {
  name: string;
  avatarUrl?: string | null;
  isAvailable?: boolean;
  driver?: Driver;
  highlightTerm?: string;
  showStatus?: boolean;
  labelClassName?: string;
  shouldIncludeRecurringStops?: boolean;
}

export const DriverNameCell: React.FC<DriverNameCellProps> = ({
  name,
  avatarUrl,
  isAvailable,
  driver,
  shouldIncludeRecurringStops,
  highlightTerm = "",
  showStatus = true,
  labelClassName = "",
}) => {
  const pendingTasks =
    driver?.tasks.filter((task) => task.status !== TaskStatus.Completed) ?? [];
  const highlightMatch = (text: string, highlight: string) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts.map((part, i) =>
          regex.test(part) ? (
            <mark key={i} className="bg-yellow-200">
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  return (
    <div className={classNames("flex items-center gap-2", labelClassName)}>
      <DriverAvatar
        driverPhoto={avatarUrl}
        driverName={name}
        size={35}
        isActive={isAvailable}
      />
      <div className="flex-1 overflow-auto">
        <div className=" text-sm font-bold truncate flex justify-between">
          {highlightMatch(name, highlightTerm)}
        </div>
        {showStatus && driver && (
          <div className="text-xs">
            <StatusDisplay
              value={
                pendingTasks.length +
                (shouldIncludeRecurringStops ? driver.numRecurringStops : 0)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
