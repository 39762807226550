import {
  Shipment,
  ShipmentTemplate,
} from "@api/graphql/generated/generated-types";

export const getShipmentName = (shipment: Shipment | null) => {
  if (shipment === null) {
    return "Unnamed Order";
  }
  if (shipment.order.name) {
    return shipment.order.name;
  }
  if (shipment.shipmentTemplate?.order.name) {
    return shipment.shipmentTemplate.order.name;
  }
  return "Order #" + shipment.order.displayId;
};

export const getShipmentTemplateName = (
  shipmentTemplate: ShipmentTemplate | null
) => {
  if (shipmentTemplate === null) {
    return "Unnamed Recurring Order";
  }
  if (shipmentTemplate.order.name) {
    return shipmentTemplate.order.name;
  }
  return "Recurring Order #" + shipmentTemplate.order.displayId;
};
